<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>
        </div>
        <dd-card title="合同列表" :height="tableHeight">
            <dd-table :columns="columnsList" :data="tableData" border :isSelection="true" show-page :loading="loading">
                <template #table-btn>
                    <dd-button type="primary" icon="add" size="medium" @click="handleAdd">新增付款计划单
                    </dd-button>
                </template>
                <template #table-btn-right>
                    <el-button icon="el-icon-s-operation" circle type="info" size="small" @click="drawerVisible = true"
                        title="列筛选"></el-button>
                </template>
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handlePageChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>

        <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
            :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
            @handleColumnsChange="handleColumnsChange">
        </dd-filter-drawer>

        <dd-popup v-if="addEditPopupVisible" width="78%" height="80%" showBtn  :saveBtn="saveBtn" :submitBtn="false"
            :show="addEditPopupVisible" :title-name="addEditPopupTitle"
            @popup-close="handleClose">
            <dd-button  slot="button" size="medium" v-if="pageProp.pageType != 'check' && pageProp.pageType != 'detail'" type="primary"
            @click="handleSubmit(0)">暂存</dd-button>
            <dd-button  slot="button" size="medium" v-if="pageProp.pageType != 'check' && pageProp.pageType != 'detail'" type="primary"
            @click="handleSubmit(1)">提交</dd-button>

            <dd-button  slot="button" size="medium" v-if="pageProp.pageType == 'check'" type="danger"
            @click="handleCheck(1)">退回</dd-button>
            <dd-button  slot="button" size="medium" v-if="pageProp.pageType == 'check'" type="success"
            @click="handleCheck(0)">审核通过</dd-button>
            <addEditPopup ref="addEditPopup" :pageProp="pageProp" slot="main"></addEditPopup>
        </dd-popup>
    </div>
</template>

<script>
import { delApi, postApi, putApi } from '@/api/request';
import addEditPopup from './components/addEditPopup.vue';
export default {
    name: 'purchasePaymentPlan',
    $dynamicRoute: {
        name: "采购付款计划 ",
        path: "/contract/purchasePaymentPlan/index",
    },
    components: { addEditPopup },
    data() {
        return {
            loading: false,
            addEditPopupVisible: false,
            saveBtn: false,
            pageProp: {},
            addEditPopupTitle: '',
            formList: [
                {
                    label: "单据编码",
                    value: "code",
                    inputType: "text",
                    placeholderText:
                        "请输入单据编码进行搜索",
                },
                {
                    label: "单据名称",
                    value: "name",
                    inputType: "text",
                    placeholderText:
                        "请输入单据名称进行搜索",
                },
                {
                    label: "单据状态",
                    value: "status",
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    defaultValue: "",
                    placeholderText: "请选择单据状态",
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                },
            ],
            drawerVisible: false,
            tableColumns: [
                {
                    title: "单据编码",
                    key: "code",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "单据名称",
                    key: "name",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "年度",
                    key: "year",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "月份",
                    key: "month",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "本期计划付款金额",
                    key: "amount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "单据状态",
                    key: "statusName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    width: 200,
                    event: [
                        
                        {
                            state: "default",
                            label: "编辑",
                            type: "warning",
                            width: 100,
                            method: (row) => {
                                this.handleEdit(row);
                            },
                            rules: (row) => {
                                let show = false;
                                if (row.status == "PC-CGJH-STATUS-ZC" || row.status == "PC-CGJH-STATUS-YTH" ) {
                                show = true;
                                } else {
                                show = false;
                                }
                                return show;
                            },
                        },
                        {
                            state: "default",
                            label: "审核",
                            width: 100,
                            type: 'warning',
                            method: (row) => {
                                this.handleProcess(row);
                            },
                            rules: (row) => {
                                let show = false;
                                if (row.status == "PC-CGJH-STATUS-YTJ" ) {
                                show = true;
                                } else {
                                show = false;
                                }
                                return show;
                            },
                        },
                        {
                            state: "default",
                            label: "查看",
                            width: 100,
                            method: (row) => {
                                this.handleRowDetail(row);
                            },
                        },
                        {
                            state: "default",
                            type: 'danger',
                            label: "删除",
                            width: 100,
                            method: (row) => {
                                this.handleDelete(row);
                            },
                            rules: (row) => {
                                let show = false;
                                if (row.status == "PC-CGJH-STATUS-ZC" ) {
                                show = true;
                                } else {
                                show = false;
                                }
                                return show;
                            },
                        },
                    ],
                },
            ],
            tableData: [],
            columnsList: [],
            tableHeight: null,
            saveBtn: false,
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
            searchData: {},
        }
    },
    created() {
        this.getDataType();
    },
    mounted() {
        this.columnsList = [...this.tableColumns]
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
        this.getTableData()
    },
    methods: {
        getTableData() {
            postApi(`/contract/payment/schedule/page/${this.current}/${this.size}`, this.searchData).then(res => {
                if(res.data.code == 0) {
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                }
            })
        },
        handleSearchEvent(e) {
            this.current = 1;
            this.searchData = e;
            this.getTableData()
        },
        handlePageChange() {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableData()
        },
        handleColumnsChange(val) {
                let list = new Set();
                this.tableColumns.map((item) => {
                    val.map((itm) => {
                        if (item.event || item.key == itm.key) {
                            list.add(item);
                        }
                    });
                });
                this.columnsList = [...list];
            },
        handleAdd() {
            this.addEditPopupTitle = '新增';
            this.pageProp.pageType = 'add'
            this.addEditPopupVisible = true;
        },
        handleProcess(row) {
            this.addEditPopupTitle = '审核';
            this.pageProp.pageType = 'check'
            this.pageProp.id = row.id;
            this.addEditPopupVisible = true;
        },
        handleCheck(e) {
            putApi(`/contract/payment/schedule/check?id=${this.pageProp.id}&type=${e}`).then(res => {
                if(res.data.code == 0) {
                    this.$message.success('操作成功')
                }
                else {
                    this.$message.warning('操作失败,请稍后重试')
                }
                this.getTableData()
                this.addEditPopupVisible = false;
            })
        },
        handleSubmit(e) {
            let params = {};
                if(e == 0) {
                    let flag = false;
                    this.$refs.addEditPopup.$refs.form_addEditPopup.$refs.form_addEditPopup.validate(
                        (valid) => {
                        if (valid) {
                            flag = true;
                        }
                        }
                    );
                    if(!flag) {
                        return false
                    }
                }
                params = {
                    ...this.$refs.addEditPopup.$refs.form_addEditPopup.formData,
                    scheduleVOS: this.$refs.addEditPopup.tableData
                }
                postApi(`/contract/payment/schedule/save/${e}`, params).then(res => {
                    if(res.data.code ==0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败')
                    }
                    this.getTableData()
                    this.addEditPopupVisible = false;

                })
        },
        handleEdit(row) {
            this.addEditPopupTitle = '编辑';
            this.addEditPopupVisible = true;
            this.pageProp.id = row.id;
            this.pageProp.pageType = 'edit'
        },
        handleRowDetail(row) {
            this.addEditPopupVisible = true;
            this.pageProp.id = row.id;
            this.pageProp.pageType = 'detail'
        },
        handleClose() {
            this.addEditPopupVisible = false;
        },
        handleDelete(row) {
            delApi(`/contract/payment/schedule/delete?id=${row.id}`).then(res => {
                if(res.data.code == 0) {
                    this.$message.success('操作成功')
                }
                else {
                    this.$message.warning('操作失败，请稍后重试')
                }
                this.getTableData()
            })
        },
        getDataType() {
            postApi(`/contract/dicttype/list/all`, {code: "PC-CGJH-STATUS"}).then(res => {
                if(res.data.code == 0) {
                    this.formList.forEach(item => {
                        if(item.value == 'status') {
                            item.optionsList = res.data.data;
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="less" scoped></style>