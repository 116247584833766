<template>
    <div class="container">
        <div class="top_box">
            <span class="top_box_name">金蝶合同关联性判断：</span>
            <div class="ibox-content" style="height: 100%">
                <el-radio-group v-model="contractType" @change="changeRadio">
                    <el-radio label="一般合同">一般合同</el-radio>
                    <el-radio label="关联合同">关联合同</el-radio>
                </el-radio-group>
            </div>
        </div>

        <div class="container_btom" v-if="contractType == '关联合同'">
            <div class="text_box">
                <div class="top_box_item" v-for="(item, index) in topText" :key="index">
                    <div class="label">{{ item.label }}：</div>
                    <div class="value">{{ item.value }}</div>
                </div>
            </div>

            <dd-table style="margin-top: 30px" ref="associationPopTable" :columns="tableColumns" :summary-method="getSummaries"
                :data="tableData" show-summary @select-change="handleSelectionChange" border :isSelection="true" 
                :loading="loading">
                <template #table-btn>
                    <dd-button type="primary" icon="add" size="medium" @click="handleAdd">添加自建合同
                    </dd-button>
                    <dd-button type="primary" icon="delete" size="medium" @click="handleDelete">移除
                    </dd-button>
                </template>

            </dd-table>
        </div>
        <div class="container_btom" v-else>
        </div>
        <dd-popup v-if="addSelfBuiltContractVisible" width="78%" height="80%" showBtn inPopup :saveBtn="false"
            :submitBtn="false" :show="addSelfBuiltContractVisible" title-name="添加自建合同" @popup-close="handleClose">
            <dd-button slot="button" size="medium" type="primary" @click="handleSubmit(1)">提交</dd-button>
            <addSelfBuiltContract ref="addSelfBuiltContract" :pageProp="pageProp" slot="main"></addSelfBuiltContract>
        </dd-popup>

    </div>

    <!-- addSelfBuiltContract -->
</template>

<script>
import { getApi, postApi } from '@/api/request';
import addSelfBuiltContract from './addSelfBuiltContract.vue'
export default {
    name: 'associationPop',
    props: ['pageProp'],
    components: {
        addSelfBuiltContract
    },
    data() {
        return {
            addSelfBuiltContractVisible: false,
            loading: false,
            contractType: '关联合同',
            topText: [
                {
                    label: "变更后合同总金额",
                    value: "",
                },
                {
                    label: "已关联金额",
                    value: "",
                },
                {
                    label: "待分配金额",
                    value: "",
                },
            ],
            tableColumns: [
                {
                    title: "自建合同编码",
                    key: "code",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建合同名称",
                    key: "name",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建合同类型",
                    key: "procurementTypeName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建合同乙方",
                    key: "partyBCompany",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建合同变更后合同总金额",
                    key: "procurementFinallyAmount",
                    align: "center",
                    overflow: true,
                    width: 190
                },
                {
                    title: "自建合同已关联金额",
                    key: "allotmentTotalAmount",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "自建合同可关联金额",
                    key: "allotmentRemainingAmount",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "项目名称",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    input: [
                        {
                            method: (value, row, index) => {
                                this.changeAmount(value, row, index);
                            },
                            isEdit: () => {
                                let state = false;
                                return state;
                            },
                            rules: (value) => {
                                let result = value.replace(/[^\d.]/g, '');
                                if (result.charAt(0) === '.') {
                                    result = result.slice(1);
                                }
                                const parts = result.split('.');
                                if (parts.length > 2) {
                                    result = parts.slice(0, 2).join('.');
                                }
                                if (parts.length > 1) {
                                    result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                }
                                return result;
                            },
                        }
                    ]
                },
            ],
            tableData: [],
            selectTableData: [],
            SelectionData: [],
        }
    },
    created() {
        this.getTableData()

    },
    mounted() {

    },

    methods: {
        getTableData() {
            postApi(`/contract/contractpurchaseinfo/associate/detail?id=${this.pageProp.id}`).then(res => {
                if (res.data.code == 0) {
                    this.contractType = (res.data.data.relateType == 'PC-DB-GLLX-GLHT' || res.data.data.relateType == null) ? '关联合同' : '一般合同';
                    this.tableData = res.data.data.list;
                    let allotmentAmountSum = 0;
                    this.tableData.forEach(item => {
                        allotmentAmountSum += (Number(item.allotmentAmount) || 0)
                    })
                    this.topText[0].value = this.pageProp.fchangeamount
                    this.topText[1].value = allotmentAmountSum
                    this.topText[2].value = (Number(this.pageProp.fchangeamount)) - Number(allotmentAmountSum)
                }
            })
        },
        changeRadio(e) {
            if (e == '一般合同') {
                this.$message.warning('合同关联性判断变为一般合同时会取消所有相关的关联关系（含结算、付款等）');
            }
        },
        changeAmount(value, row, index) {
            if (value) {
                value = Number(value)
            }
            else {
                value = 0
            }
            // if (value > (row.procurementFinallyAmount - row.allotmentTotalAmount)) {
            //     this.$message.warning('关联金额不能大于 自建合同变更后总金额-自建合同已关联金额')
            //     value = 0;
            //     this.$set(row, 'allotmentAmount', value);
            //     return false
            // }
            let sums = 0;
            this.tableData.map(item => { sums += Number(item.allotmentAmount) })
            // if (sums > (Number(this.topText[0].value))) {
            //     this.$message.warning('关联金额之和，不能大于变更后合同总金额')
            //     value = 0;
            //     this.$set(row, 'allotmentAmount', value);
            //     return false
            // }

            this.topText[1].value = sums;
            this.topText[2].value = Number(this.topText[0].value) - sums
        },
        handleAdd() {
            this.pageProp.tableData ? this.pageProp.tableData = [] : null
            if (this.tableData.length > 0) {
                this.pageProp.tableData = this.tableData
            }
            this.addSelfBuiltContractVisible = true;
        },
        handleClose() {
            this.addSelfBuiltContractVisible = false;
        },
        handleDelete() {
            if (this.SelectionData.length == 0) {
                this.$message.warning('请先勾选一条数据')
                return false;
            }
            let arr = new Set(this.SelectionData.map(item => item.id))
            this.tableData = this.tableData.filter(item => !arr.has(item.id))
            this.$refs.associationPopTable.$refs.baseTable.clearSelection();
        },
        handleSubmit() {
            let params = {
                id: this.pageProp.id,
                selfIds: this.$refs.addSelfBuiltContract.selectData.map(item => { return item.id })
            }
            postApi(`/contract/contractpurchaseinfo/selected/selfpurchase`, params).then(res => {
                if (res.data.code == 0) {
                    this.selectTableData = res.data.data;
                    this.tableData = this.tableData.concat(res.data.data)
                    this.$message.success('操作成功')
                }
                else {
                    this.$message.warning('操作失败，请检查网络后重试')
                }
                this.addSelfBuiltContractVisible = false;
            })
        },
        handleSelectionChange(e) {
            this.SelectionData = e;
        },
        getSummaries(param) { // 自定义项表格合计方法
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                } else if (column.property == "allotmentAmount"
                ) {
                    //如果是经费（正常的加减法）
                    const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                    if (!values.every((value) => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            var sum = 0;
                            if (!isNaN(value)) {
                                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                                return sum;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += " ";
                    }
                }
            });
            return sums
        },

    }
}
</script>

<style lang="less" scoped>
.top_box {
    display: flex;
    align-items: center;

    .top_box_name {
        padding: 0 15px;
        box-sizing: border-box;
        height: 45px;
        line-height: 45px;
        font-weight: bold;
        font-size: 14px;
        color: #606266;
    }

    .right_box {
        display: flex;
        align-items: center;

        .content {
            margin-right: 20px;
        }
    }
}

.container_btom {
    margin-top: 30px;

    .text_box {
        width: 80%;
        height: 35px;
        line-height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: bold;
        font-size: 15px;
        color: #606266;

        .top_box_item {
            display: flex;
            align-items: center;
        }
    }
}

.tooltip_text {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
</style>