<template>
    <div class="main-container">
        <div class="main-container">
            <div class="head_wrap">
                <dd-card title="条件搜索" spacer>
                    <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                        ref="ddSearchForm"></dd-search-form>
                </dd-card>
            </div>

            <dd-card title="" :height="tableHeight">
                <dd-table :columns="columnsList" :data="tableData" :row-class-name="tableRowClassName" :stripe="false" border :isSelection="false" show-page
                    :loading="loading">
                    <template #table-btn-right>
                        <el-button icon="el-icon-s-operation" circle type="info" size="small"
                            @click="drawerVisible = true" title="列筛选"></el-button>
                    </template>
                    <template #table-pagination>
                        <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                            @page-change="handlePageChange" align="right">
                        </dd-pagination>
                    </template>
                </dd-table>
            </dd-card>

            <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
                :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
                @handleColumnsChange="handleColumnsChange">
            </dd-filter-drawer>

            <dd-popup v-if="invoicingPopVisible" width="78%" height="80%" showBtn :saveBtn="false" :submitBtn="false"
                :show="invoicingPopVisible" title-name="关联" @popup-close="handleClose">
                <dd-button slot="button" size="medium" type="primary" @click="handleSubmit(1)">确认数据并提交</dd-button>
                <invoicingPopup ref="invoicingPopup" :pageProp="pageProp" slot="main"></invoicingPopup>
            </dd-popup>
        </div>
    </div>
</template>

<script>
import { postApi } from '@/api/request';
import invoicingPopup from './invoicingPopup.vue'
export default {
    name: 'contractPendingContract',
    $dynamicRoute: {
        name: "开票待关联",
        path: "/contract/contractPending/invoicing",
    },
    components: { invoicingPopup },
    data() {
        return {
            invoicingPopVisible: false,
            loading: false,
            formList: [
            {
                    label: "金蝶合同编号",
                    value: "purchaseCode",
                    inputType: "text",
                    placeholderText:
                        "请输入金蝶合同编号进行搜索",
                },
                {
                    label: "金蝶合同名",
                    value: "purchaseName",
                    inputType: "text",
                    placeholderText:
                        "请输入合金蝶合同名进行搜索",
                },
                {
                    label: "关联状态",
                    value: "relateState",
                    inputType: "select",
                    optionsList: [],
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                    placeholderText:
                        "请选择关联状态",
                },
            ],
            pageProp: {},
            tableColumns: [
                {
                    title: "合同发票编码",
                    key: "contractInvoiceCode",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "发票登记单据编码",
                    key: "code",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同编码",
                    key: "purchaseCode",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同名称",
                    key: "purchaseName",
                    align: "center",
                    overflow: true,
                    width: 100,
                }, {
                    title: "合同乙方",
                    key: "fpartbname",
                    align: "center",
                    overflow: true,
                    width: 100,
                },
                {
                    title: "本期开票金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "开票日期",
                    key: "invoiceDate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "工程项目",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                }, {
                    title: "关联自建合同编号",
                    key: "selfCodes",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联自建合同名称",
                    key: "selfNames",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "关联状态",
                    key: "relateState",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    event: [
                        {
                            state: "default",
                            label: "关联",
                            width: 100,
                            method: (row) => {
                                this.associationContarct(row);
                            },
                        },
                    ],
                },
            ],
            drawerVisible: false,
            tableHeight: null,
            tableData: [],
            columnsList: [],
            searchData: {},
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
        }
    },
    created() {
        this.getDataType()
    },
    mounted() {
        this.columnsList = [...this.tableColumns]
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
    },
    methods: {
        handleModify() {
            this.invoicingPopVisible = true;
        },
        associationContarct(row) {
            this.pageProp.id = row.invoiceRegistrationId;
            this.pageProp.purchaseContractId = row.purchaseContractId;
            this.pageProp.totalAmount = row.totalAmount;
            this.invoicingPopVisible = true;
        },
        handleClose() {
            this.invoicingPopVisible = false;
        },
        handleSubmit() {
            if(this.$refs.invoicingPopup.tableData && this.$refs.invoicingPopup.tableData.length) {
                let flag = true;
                let arr = this.$refs.invoicingPopup.tableData;
                for(let i = 0 ; i < arr.length; i++) {
                    if((arr[i].allotmentAmount || 0) > ((arr[i].contractRelatedAmount|| 0) - (arr[i].relatedSettleAmount|| 0))) {
                        flag = false;
                        break;
                    }
                }
                let sums = 0;
                arr.map(item => { sums += Number(item.allotmentAmount) })
                if (sums > (Number(this.$refs.invoicingPopup.topText[0].value))) {
                    this.$message.warning('关联开票金额之和，不能大于金蝶本期开票金额')
                    return false
                }
                if(!flag) {
                    this.$message.warning('关联开票金额不能大于 结算关联金额-与本金蝶合同已关联的开票金额（即可关联开票金额限值）')
                    return false;
                }
            }
            let params = {
                invoiceRelations: this.$refs.invoicingPopup.tableData
            }
            postApi(`/contract/invoice/registration/todo/update`, params).then(res => {
                if(res.data.code == 0) {
                    this.$message.success('操作成功')
                }
                else {
                    this.$message.warning('操作失败，请稍后重试')
                }
                this.invoicingPopVisible = false;
                this.getTableData();
            })
        },
        getTableData() {
            postApi(`/contract/invoice/registration/todo/page/${this.current}/${this.size}`, this.searchData).then(res => {
                if(res.data.code == 0) {
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total
                }
            })
        },
        handleColumnsChange(val) {
            let list = new Set();
            this.tableColumns.map((item) => {
                val.map((itm) => {
                    if (item.event || item.key == itm.key) {
                        list.add(item);
                    }
                });
            });
            this.columnsList = [...list];
        },
        tableRowClassName({row, rowIndex}) {
            if (row.correlationType == null) {
            return 'warning-row';
            } else {
            return '';
            }
        },
        getDataType() {
            postApi(`/contract/dicttype/list/all`, {code: 'PC-QTTODO-STATUS'}).then(res=> {
                if(res.data.code == 0) {
                    this.formList.forEach(item => {
                        if(item.value == 'relateState') {
                            item.optionsList = res.data.data
                        }
                    })
                    res.data.data.forEach(item => {
                        if(item.name == '待关联') {
                            this.formList[2].defaultValue = item.code;
                            this.searchData.relateState = item.code
                            this.$forceUpdate()
                        }
                    })
                    this.getTableData();
                }
            })
        }, 
        handleSearchEvent(e) {
            this.searchData = e;
            this.current = 1;
            this.getTableData();
        },
        handlePageChange(pageSize, pageNumber) {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableData()
        }, 
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-table {
    .warning-row {
        background: oldlace !important;
    }
}
</style>