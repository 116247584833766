<template>
  <div class="main-container">
    <div class="left-box">
      <div class="head_wrap">
        <dd-card title="条件搜索" spacer>
          <dd-search-form
            :form-config="formList"
            @search-click="handleSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </dd-card>
      </div>
      <dd-card title="字典类型列表" :height="tableHeight">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          ref="leftTable"
          border
          isSelection
          show-page
          :loading="loading"
          @row-click="handleTableRow"
          @select="handleSelect"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >新增</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <div class="right-box">
      <div class="head_wrap">
        <dd-card title="条件搜索" spacer>
          <dd-search-form
            :form-config="rightFormList"
            @search-click="handleRightSearchEvent"
            ref="ddRightSearchForm"
          ></dd-search-form>
        </dd-card>
      </div>
      <dd-card title="字典列表" :height="tableHeight">
        <dd-table
          :columns="rightTableColumns"
          :data="rightTableData"
          ref="rightTable"
          border
          isSelection
          :show-page="false"
          :loading="rightLoading"
          @select-change="handleRightTableSelect"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleRightAdd"
              >新增</dd-button
            >
            <!-- <dd-button type="danger" size="medium" icon="delete" @click="handleArrDelete">删除</dd-button> -->
          </template>
        </dd-table>
      </dd-card>
    </div>
    <dd-popup
      width="30%"
      height="42%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></add-pop>
    </dd-popup>
    <dd-popup
      width="30%"
      height="50%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="catalogPopupVisible"
      :title-name="rightPoptitleName"
      @popup-close="handleClose"
      @popup-save="handleRightSave('addPopForm', 'baseForm')"
    >
      <catalog-pop
        v-if="catalogPopupVisible"
        slot="main"
        ref="addRightPop"
        :rowData="rightRowData"
        :dictType="rowData.name"
      ></catalog-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import catalogPop from "./components/AddCatalogPop.vue";
export default {
  name: "ContractDict",
  $dynamicRoute: {
    name: "合同字典",
    path: "/Contract/dict",
  },
  components: {
    addPop,
    catalogPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: false,
      rightLoading: false,
      formData: {},
      rightFormData: {},
      leftSelectData: null,
      formList: [
        {
          label: "类型名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入类型名称",
        },
      ],
      rightFormList: [
        {
          label: "字典名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入字典名称",
        },
      ],
      tableColumns: [
        {
          title: "类型名称",
          key: "name",
          align: "center",
        },
        {
          title: "标识",
          key: "code",
          align: "center",
        },
        {
          title: "排序",
          key: "sortOrder",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "warning",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row, "left");
              },
              type: "danger",
            },
          ],
        },
      ],
      rightTableColumns: [
        {
          title: "字典编码",
          key: "code",
          align: "center",
        },
        {
          title: "字典名称",
          key: "name",
          align: "center",
        },
        {
          title: "描述",
          key: "description",
          align: "center",
        },
        {
          title: "排序",
          key: "sortOrder",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRightRowEditor(row);
              },
              type: "warning",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row, "right");
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      rightTableData: [],
      current: 1,
      size: 20,
      total: null,
      dictType: [],
      addPopupVisible: false,
      catalogPopupVisible: false,
      rowData: null,
      rightRowData: null,
      poptitleName: "新增",
      rightPoptitleName: "新增",
      rightSelection: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/contract/dicttype/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
          this.rowData = null;
        }
      });
    },
    // 表格行点击选择事件
    handleTableRow(row) {
      let data = [];
      if (row == this.rowData) {
        row = null;
      } else {
        this.rowData = row;
        data.push(row);
      }
      this.handleSelect(data, row);
    },
    // 复选框单选事件
    handleSelect(selection, row) {
      this.selection = selection;
      this.rowData = row;
      this.$refs.leftTable.$refs.baseTable.clearSelection();
      if (selection.length == 0) {
        this.$refs.leftTable.$refs.baseTable.setCurrentRow(null);
        this.clearRightTable();
        return;
      }
      this.$refs.leftTable.$refs.baseTable.toggleRowSelection(row, true);
      this.$refs.leftTable.$refs.baseTable.setCurrentRow(row);
      this.leftSelectData = row;
      this.getRightTableData();
      this.handleRightTableSelect([]);
    },
    // 复选框选择事件
    handleRightTableSelect(selection) {
      this.rightSelection = selection;
      if (selection.length == 0) {
        this.$refs.rightTable.$refs.baseTable.clearSelection();
        return;
      }
    },
    // 右侧表格数据
    getRightTableData() {
      let { rightFormData, leftSelectData } = this;
      let params = {
        ...rightFormData,
        code: leftSelectData.code,
      };
      this.rightLoading = true;
      postApi(`/contract/dicttype/list/all`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.rightLoading = false;
          this.rightTableData = data.data;
          this.rightRowData = null;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
      this.rowData = null;
    },
    handleRightAdd() {
      if (this.leftSelectData == null) {
        this.$message.warning("请先选择字典类型");
        return;
      }
      this.catalogPopupVisible = true;
      this.rightPoptitleName = "新增";
      this.rightRowData = null;
    },
    // 新增弹框关闭事件
    handleClose() {
      this.addPopupVisible = false;
      this.catalogPopupVisible = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
    },
    handleRightRowEditor(row) {
      this.catalogPopupVisible = true;
      this.rightRowData = row;
      this.rightPoptitleName = "编辑";
    },
    //列表单行删除
    handleRowDel(row, type) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        if (type == "left") {
          this.handleConfirmDelete(`/contract/dicttype/delete?id=${row.id}`, type);
        } else {
          this.handleConfirmDelete(`/contract/dict/delete?id=${row.id}`, type);
        }
      });
    },

    // 批量删除
    // handleArrDelete() {
    //     if(this.leftSelectData == null) {
    //         this.$message.warning("请先选择项目类型");
    //         return;
    //     }
    //     if(this.rightSelection.length == 0) {
    //         this.$message.warning("请至少选择一条数据删除");
    //         return
    //     }
    //     this.$confirm(`确认删除所选数据吗？`, "提示", {
    //         confirmButtonClass: "btnsearch",
    //         confirmButtonText: "确认",
    //         cancelButtonClass: "btnreset",
    //         cancelButtonText: "取消",
    //         showClose: true,
    //         type: "warning",
    //     }).then(() => {
    //         this.handleRightConfirmDelete();
    //     });
    // },
    // 确认删除请求
    handleConfirmDelete(url, type) {
      delApi(url).then((res) => {
        let { data } = res;
        if (data.code == 0 && data.data) {
          this.$message.success("删除成功");
          if (type == "left") {
            this.getTableData();
          } else {
            this.getRightTableData();
          }
        }
      });
    },
    // handleRightConfirmDelete() {
    //     let arr = [];
    //     this.rightSelection.forEach(item => {
    //         arr.push(item.id)
    //     })
    //     let params = {
    //         dirIds: arr,
    //         typeId: this.typeId
    //     }
    //     delApi(`/project/catalogtypedir`,params).then((res) => {
    //         let { data } = res;
    //         if (data.code == 0 && data.data) {
    //             this.$message.success("删除成功");
    //         }
    //         this.getRightTableData();
    //     });
    // },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
      this.handleSelect([]);
      this.leftSelectData = null;
      this.getRightTableData();
    },
    handleRightSearchEvent(obj) {
      if (this.leftSelectData == null) {
        this.$message.warning("请先选择字典类型");
        return;
      }
      this.rightFormData = obj;
      this.rightCurrent = 1;
      this.getRightTableData();
      this.handleRightTableSelect([]);
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    handleRightCurrentChange(size, number) {
      this.rightCurrent = number;
      this.rightSize = size;
      this.getRightTableData();
    },
    // 清除右侧表格数据
    clearRightTable() {
      this.$refs.ddRightSearchForm.formData = {};
      this.leftSelectData = null;
      this.rightTableData = [];
      this.rightTotal = 0;
      this.rightSize = 20;
      this.rightCurrent = 1;
      this.rightSelection = [];
      this.rowData = null;
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.rowData != null) {
          params.id = this.rowData.id;
          putApi(`/contract/dicttype`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          postApi(`/contract/dicttype`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
    handleRightSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addRightPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addRightPop.$refs[formName].formData;
      params.typeId = this.leftSelectData.id;
      if (flag) {
        if (this.rightRowData != null) {
          params.id = this.rightRowData.id;
          console.log('params', params)
          putApi(`/contract/dict`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getRightTableData();
            }
          });
        } else {
          console.log('params', params)
          postApi(`/contract/dict`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getRightTableData();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  display: flex;
  justify-content: space-between;
  .left-box {
    width: 43%;
    /deep/ .has-gutter {
      .el-table-column--selection {
        .cell {
          display: none;
        }
      }
    }
  }
  .right-box {
    width: 56%;
  }
  /deep/ .search-form-input {
    width: 50% !important;
  }
}
</style>
