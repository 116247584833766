<template>
    <div class="container">
        <div class="allocation_top">
            <div class="top_box">
                <div class="top_box_item" v-for="(item, index) in topText" :key="index">
                    <div class="label">{{ item.label }}：</div>
                    <div class="value">{{ item.value }}</div>
                </div>
            </div>
        </div>
        <div class="table_box" :height="tableHeight">
            <dd-table ref="baseTable_1" :columns="tableColumns" :summary-method="getSummaries" :data="tableData"
                show-summary border :isSelection="false" :loading="loading">
            </dd-table>
        </div>
        <dd-popup v-if="popup_formVisible" width="30%" height="45%" inPopup showBtn :saveBtn="true" :submitBtn="false"
            :show="popup_formVisible" title-name="编辑" @popup-save="handleSave()" @popup-close="handleClose">
            <div slot="main">
                <dd-card title="">
                    <dd-form :form-config="formList_form" :flex="1" :labelWidth="'220px'" ref="EditForm"
                        formRef="EditForm"></dd-form>
                </dd-card>
            </div>
        </dd-popup>
    </div>
</template>

<script>
import { getApi, postApi } from '@/api/request';

export default {
    name: 'invoiceAssociation',
    props: ['pageProp'],
    data() {
        return {
            rowData: {},
            popup_formVisible: false,
            formList_form: [
                {
                    label: "金蝶合同发票编码",
                    value: "code",
                    inputType: "text",
                    disabled: true,
                    defaultValue: "",
                },
                {
                    label: "金蝶合同编码",
                    value: "fnumber",
                    inputType: "text",
                    disabled: true,
                    defaultValue: "",
                },
                {
                    label: "金蝶合同名称",
                    value: "fname",
                    inputType: "text",
                    disabled: true,
                    defaultValue: "",
                },
                {
                    label: "关联发票金额",
                    value: "allotmentAmount",
                    inputType: "text",
                    disabled: false,
                    defaultValue: "",
                    rules: [
                        {
                            required: true,
                            message: "请输入关联发票金额",
                            trigger: "blur",
                        },
                        {
                            required: true,
                            pattern: /^\d*\.?\d{0,2}$/,
                            message: "请输入数字，小数最多两位",
                            trigger: "blur",
                        }
                    ],
                },
            ],
            topText: [
                {
                    label: "已结算总金额",
                    value: "",
                },
            ],
            tableColumns: [
                {
                    title: "金蝶合同发票编码",
                    key: "code",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "金蝶合同编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "金蝶合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "合同乙方",
                    key: "fpartbname",
                    align: "center",
                    overflow: true,
                },

                {
                    title: "开票日期",
                    key: "invoiceDate",
                    align: "center",
                    overflow: true,
                }, {
                    title: "工程项目",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                }, {
                    title: "结算关联金额",
                    key: "settleRelatedAmount",
                    align: "center",
                    overflow: true,
                    width: 120
                }, {
                    title: "本期开票金额",
                    key: "foriginalamount",
                    align: "center",
                    overflow: true,
                    width: 120
                }, {
                    title: "已关联其他自建合同的发票金额",
                    key: "linedOther",
                    align: "center",
                    overflow: true,
                    width: 220
                }, {
                    title: "数据状态",
                    key: "status",
                    align: "center",
                    overflow: true,
                    width: 100
                }, {
                    title: "关联发票金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    width: 160
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    width: 100,
                    event: [
                        {
                            state: "default",
                            label: "编辑",
                            type: "warning",
                            width: 100,
                            method: (row) => {
                                this.handleEdit(row);
                            },
                        },
                    ],
                },
            ],
            tableData: [],
            tableHeight: null,
            loading: false,
        }
    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
    },
    methods: {
        handleEdit(row) {
            this.rowData = row;
            this.popup_formVisible = true;
            this.$nextTick(() => {
                this.formList_form[0].defaultValue = this.rowData.code;
                this.formList_form[1].defaultValue = this.rowData.fnumber;
                this.formList_form[2].defaultValue = this.rowData.fname;
                this.formList_form[3].defaultValue = this.rowData.allotmentAmount;
                this.$forceUpdate()
            })
        },
        getPageData() {
            getApi(`/contract/selfestablished/procurement/allocate`, { id: this.pageProp.id, type: 'invoice' }).then(res => {
                if (res.data.code == 0) {
                    let { data } = res;
                    this.tableData = data.data;
                }
            })
            getApi(`/contract/selfestablished/procurement/allocate`, { id: this.pageProp.id, type: 'settle' }).then(res => {
                if (res.data.code == 0) {
                    let { data } = res;
                    this.topText[0].value = 0
                    if (data.data && data.data.length) {
                        data.data.map(item => {
                            this.topText[0].value += Number(item.allotmentAmount)
                        })
                    }
                }
            })
        },
        handleSave() {
            let flag = false;
            this.$refs.EditForm.$refs.EditForm.validate(
                (valid) => {
                    if (valid) {
                        flag = true;
                    }
                }
            );
            if (!flag) {
                return false
            }
            if ((this.rowData.foriginalamount * 1 || 0 - this.rowData.linedOtherPurchase * 1 || 0) < this.$refs.EditForm.formData.allotmentAmount) {
                this.$message.warning('关联金额不能大于 本期开票金额-已关联其他自建合同的发票金额（即金蝶可关联金额）')
                return false;
            }
            let sum = 0;
            this.tableData.forEach(item => {
                if (item.id != this.rowData.id) {
                    sum += ((item.allotmentAmount * 1) || 0)
                }
                else {
                    sum += Number(this.$refs.EditForm.formData.allotmentAmount) || 0
                }
            })
            if (sum > Number(this.topText[0].value)) {
                this.$message.warning('关联金额之和，不能大于自建合同的结算金额')
                return false;
            }
            const params = {
                id: this.pageProp.id,
                relationId: this.rowData.id,
                type: 'invoice',
                ...this.$refs.EditForm.formData
            }
            postApi(`/contract/selfestablished/procurement/update`, params).then(res => {
                if (res.data.code == 0) {
                    this.$message.success('操作成功')
                }
                else {
                    this.$message.success('操作失败，请稍后重试')
                }
                this.getPageData()
            })
            this.popup_formVisible = false;
        },
        handleClose() {
            this.popup_formVisible = false;
        },
        getSummaries(param) { // 自定义项表格合计方法
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                } else if (column.property == "allotmentAmount"
                ) {
                    //如果是经费（正常的加减法）
                    const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                    if (!values.every((value) => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            var sum = 0;
                            if (!isNaN(value)) {
                                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                                return sum;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += " ";
                    }
                }
            });
            return sums
        },
    }
}
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
.allocation_top {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.top_box {
    width: 80%;
    height: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
    justify-content: left;
    font-weight: bold;
    font-size: 15px;
    color: #606266;

    .top_box_item {
        display: flex;
        align-items: center;
    }
}

.table_box {
    margin-top: 30px;
}
</style>