<template>
    <div class="container">
        <dd-card title="">
            <dd-form :form-config="formList" :labelWidth="'220px'" :flex="2" ref="assignForm_formList"
                formRef="assignForm_1"></dd-form>
        </dd-card>
        <dd-card title="">
            <dd-table :columns="tableColumns" :data="tableData" border :isSelection="false" :summary-method="getSummaries" show-page show-summary :loading="loading">
            </dd-table>
        </dd-card>
    </div>
</template>

<script>
import { postApi } from '@/api/request';

export default {
    name: 'association',
    props: ['pageProp'],
    data() {
        return {
            formList: [
                {
                    label: "金蝶结算单编号",
                    value: "fnumber",
                    defaultValue: '',
                    inputType: "text",
                    disabled: true,
                },
                {
                    label: "金蝶结算单名称",
                    value: "fname",
                    defaultValue: '',
                    inputType: "text",
                    disabled: true,
                },
                {
                    label: "金蝶含税结算总金额",
                    value: "fseletleamount",
                    defaultValue: '',
                    inputType: "text",
                    disabled: true,
                },
                {
                    label: "已关联的结算总金额",
                    defaultValue: '',
                    value: "correlationAmount",
                    inputType: "text",
                    disabled: true,
                },
            ],
            tableData: [],
            tableColumns: [
                {
                    title: "自建合同编码",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建合同编码",
                    key: "fname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建合同乙方",
                    key: "partyBCompany",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "项目名称",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同关联金额",
                    key: "contractRelatedAmount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "与本金蝶合同已关联的结算金额",
                    key: "relatedSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 220,
                },
                {
                    title: "数据状态",
                    key: "status",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "归属部门",
                    key: "deptId",
                    align: "center",
                    overflow: true,
                    width: 230,
                    filterable: true,
                    selectInput: 
                        {
                            placeholderText: "请选择归属部门",
                            options: [],
                        }
                    
                },
                {
                    title: "关联结算金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    input: [
                        {
                            method: (value, row, index) => {
                                this.changeAmount(value, row, index);
                            },
                            isEdit: () => {
                                let state = false;
                                return state;
                            },
                            rules: (value) => {
                                let result = value.replace(/[^\d.]/g, '');
                                if (result.charAt(0) === '.') {
                                    result = result.slice(1);
                                }
                                const parts = result.split('.');
                                if (parts.length > 2) {
                                    result = parts.slice(0, 2).join('.');
                                }
                                if (parts.length > 1) {
                                    result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                }
                                return result;
                            },
                        }
                    ]
                },
            ],
            loading: false,
        }
    },
    created() {
        this.getDataType();
        this.getTableData();
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.assignForm_formList.formData = {
            fnumber: this.pageProp.fnumber,
            fname: this.pageProp.fname,
            fseletleamount: this.pageProp.fseletleamount,
            correlationAmount: this.pageProp.correlationAmount,
        }
        })
    },
    methods: {
        getTableData() {
            postApi(`/contract/contractpurchaseinfosettle/settle/detail?id=${this.pageProp.id}&purchaseInfoId=${this.pageProp.purchaseInfoId}`).then(res => {
                if(res.data.code == 0) {
                    this.tableData = res.data.data;
                }
            })
        },
        changeAmount(value, row, index) {
            if (value) {
                value = Number(value)
            }
            else {
                value = 0
            }
        },
        getDataType(){
            postApi(`/admin/dept/getdeptlistbyorgid`, {orgId: sessionStorage.getItem('orgId')}).then(res => {
                if(res.data.code == 0) {
                    this.tableColumns.forEach(item => {
                        if(item.key == 'deptId') {
                            item.selectInput.options = res.data.data.map(item => {
                                return {
                                    value: item.deptId,
                                    label: item.name,
                                }
                            })
                        }
                    })
                }
            })
        },
        getSummaries(param) { // 自定义项表格合计方法
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                } else if (column.property == "allotmentAmount"
                ) {
                    //如果是经费（正常的加减法）
                    const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                    if (!values.every((value) => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            var sum = 0;
                            if (!isNaN(value)) {
                                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                                return sum;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += " ";
                    }
                }
            });
            return sums
        },
    }
}
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .el-input__icon{
    line-height: 1;
}
</style>