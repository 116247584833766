import { render, staticRenderFns } from "./BasicInformationChange.vue?vue&type=template&id=223b648f&scoped=true"
import script from "./BasicInformationChange.vue?vue&type=script&lang=js"
export * from "./BasicInformationChange.vue?vue&type=script&lang=js"
import style0 from "./BasicInformationChange.vue?vue&type=style&index=0&id=223b648f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "223b648f",
  null
  
)

export default component.exports